import React from "react";
import { ADD_EXPENSES_DATA, DELETE_EXPENSES, EXPENSE_CATEGORY_ITEM_DATA, EXPENSE_CATEGORY_ITEM_DATA_DROPDOWN, EXPENSE_CATEGORY_LIST, EXPENSE_SUB_CATEGORY_ITEM_DATA, EXPENSE_SUB_CATEGORY_ITEM_DATA_DROPDOWN, EXPENSE_SUB_CATEGORY_LIST, TODAY_EXPENSE_LIST, TODAY_TOTAL_COST, UPDATE_EXPENSES, UPDATE_EXPENSE_BILL_IMAGE } from "../../Network/ApiPaths";
import getResponseClient from "../../Network/GetClientDataResponse";
import postClientDataResponse from "../../Network/PostClientDataResponse";
import Uri from "../../Network/Uri";
import ExpensesComponent from "./ExpensesComponents";

let clientHost;
let storeFresh;
let storeName;
let gst;
let storeUserId;
let storeIdValue;
let longitude;
let latitude;
let storeAddress;
let storeDet;
class ExpensesContainer extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;
      storeUserId = storeDet.userId;
      longitude = storeDet.longitude;
      latitude = storeDet.latitute;
      storeName = storeDet.storeName;
      storeAddress = storeDet.address;
      gst = storeDet.gst;
    } else {
      storeIdValue = "";
      storeUserId = "";
      longitude = "";
      latitude = "";
      storeName = "";
      storeAddress = "";
      gst = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      clientHost = storeFresh.host;
      storeName = storeFresh.storeName;
    }
    this.state = {
      expensesCategories: [],
      expensesSubCategories: [],
      expCategoryValue: "",
      subCatSelectVal: "",
      havingSubCategory: "NO",
      showSubCategoryList: false,
      componentPage: "todayexpenses",
      showCatError: false,
      showSubCarError: false,
      billFile: "",
      editedBillFile: "",
      dataAdded: false,
      expenseList: [],
      todayExpenses: [],
      totalCost: ""
    };
  }

  finalSubmit = () => {
    this.setState({
      componentPage: "todayexpenses",
      expCategoryValue: "",
      subCatSelectVal: ""
    })
    this.getTodaysTotalCost();
    this.getTodayExpenses();
  }

  handleCategoryChange = (event) => {
    let selectedOption = event.target.options[event.target.selectedIndex];
    this.setState({
      expCategoryValue: event.target.value,
      subCatSelectVal: "",
      componentPage: "todayexpenses",
      havingSubCategory: selectedOption.className
    });
    this.getTodaysTotalCost();
    this.getTodayExpenses();
  }

  handleSubCategoryChange = (event) => {
    this.setState({
      subCatSelectVal: event.target.value,
      componentPage: "todayexpenses",
    });
    this.getTodaysTotalCost();
    this.getTodayExpenses();
  }

  updateFile = (event) => {
    this.setState({
      billFile: event.target.files[0]
    })
  }

  updateEditFile = (event) => {
    this.setState({
      editedBillFile: event.target.files[0]
    })
  }


  addAllExepnsesdata = () => {
    if (this.state.expCategoryValue === '') {
      // this.setState({
      //   showCatError: true,
      //   showSubCarError: false
      // })
      alert("Please Select Expenses Category")
    } else if (this.state.expCategoryValue !== '' && this.state.havingSubCategory === "YES" && this.state.subCatSelectVal === "") {
      // this.setState({
      //   showCatError: false,
      //   showSubCarError: true
      // })
      alert("Please Select Expenses Sub Category")
    } else {
      this.setState({
        componentPage: "addexpenses",
        showCatError: false,
        showSubCarError: false
      })
    }
  }
  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  UploadFileById = (id, file, type) => {
    let img = file
    const handleFileRead = async (event) => {
      const file = img;
      const base64 = await this.convertBase64(file);
      const requestBody = {
        id: id,
        image: base64,
      };
      postClientDataResponse(
        clientHost + UPDATE_EXPENSE_BILL_IMAGE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            billFile: "",
            editedBillFile: ""
          })
          if(type === 'update'){
            this.getTodayExpenses();
            this.getTodaysTotalCost();
          }
        },
        (onError) => {
          console.log(onError.message);
        }
      );
    };
    if (img === '' || img === null || img === undefined) {
      console.log("No files Selected")
    } else {
      handleFileRead();
    }
  }

  addAllExpensesData = (rowData) => {
    var nameAndId = rowData.name
    var parts = nameAndId.split('/');
    var expense_id = parts[0];
    var name = parts[1];
    const requestBody = {
      name: name,
      expense_id: expense_id,
      category_id: this.state.expCategoryValue,
      subcategory_id: this.state.subCatSelectVal,
      store_id: storeFresh.storeId,
      quantity: rowData.quantity,
      cost: rowData.cost,
      unit: rowData.unit
    };
    postClientDataResponse(
      clientHost + ADD_EXPENSES_DATA,
      storeFresh.key,
      requestBody,
      async (onSuccessResponse) => {
        const uploadResult = await this.UploadFileById(onSuccessResponse.data.result, this.state.billFile, 'Add');
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getAllExpenseItemData = () => {
    if (this.state.havingSubCategory === 'YES') {
      var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_ITEM_DATA_DROPDOWN)
        .addQueryParam("category_id", this.state.expCategoryValue)
        .addQueryParam("subcat_id", this.state.subCatSelectVal);
    } else {
      var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_ITEM_DATA_DROPDOWN)
        .addQueryParam("category_id", this.state.expCategoryValue)
    }
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expenseList: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getexpensesCategories() {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_LIST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expensesCategories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }


  getTodayExpenses = () => {
    var apiPath = new Uri(clientHost + TODAY_EXPENSE_LIST)
    .addQueryParam('store_id', storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          todayExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getTodaysTotalCost = () => {
    var apiPath = new Uri(clientHost + TODAY_TOTAL_COST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          totalCost: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }



  getExpenseSubCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_LIST)
      .addQueryParam("category_id", this.state.expCategoryValue)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          expensesSubCategories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  updateExpenses = (data) => {
    const requestBody = {
      quantity: data.quantity,
      cost: data.cost,
      id: data.id
    };

    postClientDataResponse(
      clientHost + UPDATE_EXPENSES,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        if(this.state.editedBillFile !== ""){
          const uploadResult = this.UploadFileById(data.id, this.state.editedBillFile, 'update');
        }else{
          this.getTodayExpenses();
          this.getTodaysTotalCost();
        }
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  deleteExpense = (data) => {
    var apiPath = new Uri(clientHost + DELETE_EXPENSES)
      .addQueryParam("exp_id", data)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getTodayExpenses();
        this.getTodaysTotalCost();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  componentDidMount() {
    this.getexpensesCategories();
    this.getTodaysTotalCost();
    this.getTodayExpenses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.expCategoryValue !== this.state.expCategoryValue) {
      this.getExpenseSubCategories()
      if (this.state.havingSubCategory === "YES") {
        this.setState({
          showSubCategoryList: true
        })
      } else {
        this.getAllExpenseItemData()
        this.setState({
          showSubCategoryList: false
        })
      }
    }
    if (prevState.subCatSelectVal !== this.state.subCatSelectVal) {
      if (this.state.subCatSelectVal !== '') {
        this.getAllExpenseItemData()
      }
    }
  }

  render() {
    return (
      <>
        <ExpensesComponent
          expensesCategories={this.state.expensesCategories}
          expensesSubCategories={this.state.expensesSubCategories}
          handleCategoryChange={this.handleCategoryChange}
          subCatSelectVal={this.state.subCatSelectVal}
          handleSubCategoryChange={this.handleSubCategoryChange}
          showSubCategoryList={this.state.showSubCategoryList}
          componentPage={this.state.componentPage}
          addAllExepnsesdata={this.addAllExepnsesdata}
          showCatError={this.state.showCatError}
          showSubCarError={this.state.showSubCarError}
          addAllExpensesData={this.addAllExpensesData}
          expCategoryValue={this.state.expCategoryValue}
          updateFile={this.updateFile}
          expenseList={this.state.expenseList}
          finalSubmit={this.finalSubmit}
          todayExpenses={this.state.todayExpenses}
          totalCost={this.state.totalCost}
          deleteExpense={this.deleteExpense}
          updateEditFile={this.updateEditFile}
          updateExpenses={this.updateExpenses}
        />
      </>
    )
  }
}

export default ExpensesContainer
