import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
import Uri from "../../Network/Uri";
import getResponseClient from "../../Network/GetClientDataResponse";
let clientHost;
let storeFresh;
class ExpensesChart extends Component {
  constructor(props) {
    super(props);
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }

    this.state = { 
        monthlyData: [],
    };
  }
  static defaultProps = {
    displayTitle: true,
    displayLegend: true,
    legendPosition: "right",
    location: "City",
  };
  render() {
    let finalAmount = this.state.monthlyData
    ? this.state.monthlyData
      .map((p) => parseFloat(p.final_amount).toFixed(3))
    : null;
//   let storeName = this.state.monthlyData
//     ? this.state.monthlyData
//       .map((p) => p.Month)
//     : null;
  const data1 = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient2 = ctx.createLinearGradient(146.0, 0.0, 154.0, 300.0);
    gradient2.addColorStop(0, "rgba(240, 99, 81, 0.85)");
    gradient2.addColorStop(1, "rgba(240, 99, 81, 0.85)");
    return {
      labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      datasets: [
        {
          label: "Expenses in Rupees",
          backgroundColor: [
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
          ],
          hoverBackgroundColor: [
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
          ],
          borderColor: [
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
            gradient2,
          ],
          borderWidth: 1,
          data: finalAmount ? finalAmount : [0,0, 0, 0, 0, 0, 0, 0,0,0,0,0],
        },
      ],
    };
  };
  return (
    <div className="chart">
      <Bar
        data={data1}
        options={{
          legend: { labels: { fontColor: "#777", fontSize: 12 } },
          scales: {
            xAxes: [
              {
                display: true,
                gridLines: {
                  color: "#eee",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                gridLines: {
                  color: "#eee",
                },
                ticks: {
                  suggestedMin: 0,
                },
              },
            ],
          },
        }}
      />
    </div>
  );



  }


  getData = ()=>{
    var apiPath = new Uri(clientHost + `Expenses/listExpenseReportsMonthWise`)
    .addQueryParam("store_id", storeFresh.storeId)
    .addQueryParam("year", this.props.currentYear);
  getResponseClient(
    apiPath,
    storeFresh.key,
    (onSuccessResponse) => {
      this.setState({
        monthlyData: onSuccessResponse.result,
      });

    },
    (onError) => {
      console.log(onError.message);
    }
  );
  }

  async componentDidMount() {
        this.getData()
  }

  componentDidUpdate(prevprops, prevState){
    if(prevprops.currentYear !== this.props.currentYear){
        this.getData()
    }
  }
}

export default ExpensesChart;
