import React from "react";

class StoresComponent extends React.Component{
constructor(props){
  super();
}
render(){
return(
  <section className="projects no-padding-top">
    <div className="container-fluid">
      <div className="card-header d-flex align-items-center">
        <h3 className="h4">Stores</h3>
        <div className="card-body text-center">
          <div
            id="myModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    ADD Store_Details
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                  ref={(el) =>
                    this.props.reset === false
                      ? (this.myFormRef1 = el)
                      : this.myFormRef1.reset()
                  }
                  >
                    <div className="form-group row">
                      <label
                        className="col-sm-3" // form-control-label
                        style={
                          this.props.newStoreNameerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Name
                      </label>
                      <div className="col-sm-9">
                        <input
                          style={
                            this.props.newStoreNameerror === true
                              ? { color: "red" }
                              : null
                          }
                          onChange={this.props.fieldChange}
                          type="name"
                          name="newStoreName"
                          placeholder="Enter Store Name"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" // form-control-label
                        style={
                          this.props.newStoreAddresserror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Address
                      </label>
                      <div className="col-sm-9">
                        <input
                          style={
                            this.props.newStoreAddresserror === true
                              ? { color: "red" }
                              : null
                          }
                          onChange={this.props.fieldChange}
                          type="name"
                          name="newStoreAddress"
                          placeholder="Enter Store Addtess"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        className="col-sm-3" // form-control-label
                        style={
                          this.props.newStoreManagerNameerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Manager
                      </label>
                      <div className="col-sm-9">
                        <input
                          style={
                            this.props.newStoreManagerNameerror === true
                              ? { color: "red" }
                              : null
                          }
                          onChange={this.props.fieldChange}
                          type="name"
                          name="newStoreManagerName"
                          placeholder="Enter Store Manager Name"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" // form-control-label
                        style={
                          this.props.newStoreEmailerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Email
                      </label>
                      <div className="col-sm-9">
                        <input
                          style={
                            this.props.newStoreEmailerror === true
                              ? { color: "red" }
                              : null
                          }
                          onChange={this.props.fieldChange}
                          type="email"
                          name="newStoreEmail"
                          placeholder="Enter Store Email"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" //form-control-label"
                        style={
                          this.props.newStorePhoneNumbererror ||
                          this.props.newStorePhoneNumber_Oneerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Phone
                      </label>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newStorePhoneNumbererror === true
                                  ? { color: "red" }
                                  : null
                              }
                              onChange={this.props.fieldChange}
                              type="number"
                              placeholder="Enter Phone Number"
                              name="newStorePhoneNumber"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newStorePhoneNumber_Oneerror === true
                                  ? { color: "red" }
                                  : null
                              }
                              onChange={this.props.fieldChange}
                              type="number"
                              name="newStorePhoneNumber_One"
                              placeholder=" Enter Phone Number"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" //form-control-label"
                        style={
                          this.props.newStoreRadiuserror ||
                          this.props.newStoreTimingerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Range
                      </label>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newStoreRadiuserror === true
                                  ? { color: "red" }
                                  : null
                              }
                              onChange={this.props.fieldChange}
                              type="string"
                              name="newStoreRadius"
                              placeholder="Enter Store Radius"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="col-sm-6">
                            <input
                              style={
                                this.props.newStoreTimingerror === true
                                  ? { color: "red" }
                                  : null
                              }
                              list="timings"
                              name="newStoreTiming"
                              onChange={this.props.fieldChange}
                              placeholder="Store Timing"
                              className="form-control form-control-sm"
                            />
                            <datalist id="timings">
                              <option value="9A.M to 10P.M"></option>
                              <option value="10A.M to 10P.M"></option>
                              <option value="11A.M to 11P.M"></option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" //form-control-label"
                        style={
                          this.props.newStoreLongitudeerror ||
                          this.props.newStoreLatitudeerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Location
                      </label>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newStoreLongitudeerror === true
                                  ? { color: "red" }
                                  : null
                              }
                              onChange={this.props.fieldChange}
                              type="number"
                              placeholder="Enter Longitude"
                              name="newStoreLongitude"
                              className="form-control form-control-sm"
                            />
                          </div>
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newStoreLatitudeerror === true
                                  ? { color: "red" }
                                  : null
                              }
                              onChange={this.props.fieldChange}
                              type="number"
                              name="newStoreLatitude"
                              placeholder=" Enter Longitude"
                              className="form-control form-control-sm"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" //form-control-label"
                        style={
                          this.props.newService_statuserror ||
                          this.props.newStatus_reasonerror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        Service
                      </label>
                      <div className="col-sm-9">
                        <div className="row">
                          <div className="col-md-6">
                            <input
                              style={
                                this.props.newService_statuserror === true
                                  ? { color: "red" }
                                  : null
                              }
                              list="status"
                              name="newService_status"
                              onChange={this.props.fieldChange}
                              placeholder="Service_status"
                              className="form-control form-control-sm"
                            />
                            <datalist id="status">
                              <option value="OPEN"></option>
                              <option value="CLOSE"></option>
                            </datalist>
                          </div>
                          <div className="col-sm-6">
                            <input
                              style={
                                this.props.newStatus_reasonerror === true
                                  ? { color: "red" }
                                  : null
                              }
                              list="reason"
                              name="newStatus_reason"
                              onChange={this.props.fieldChange}
                              placeholder="status_reason"
                              className="form-control form-control-sm"
                            />
                            <datalist id="reason">
                              <option value="ACTIVE"></option>
                              <option value="INACTIVE"></option>
                            </datalist>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        className="col-sm-3" // form-control-label
                        style={
                          this.props.newStore_Gsterror === true
                            ? { color: "red" }
                            : null
                        }
                      >
                        GST
                      </label>
                      <div className="col-sm-9">
                        <input
                          style={
                            this.props.newStore_Gsterror === true
                              ? { color: "red" }
                              : null
                          }
                          onChange={this.props.fieldChange}
                          type="string"
                          name="newStore_Gst"
                          placeholder="Enter GST"
                          className="form-control form-control-sm"
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <input
                        type="submit"
                        value="ADD"
                        data-dismiss={
                          this.props.addedStore === true ? "modal" : null
                        }
                        className="btn btn-primary"
                        onClick={this.props.addStore}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body text-center">
          <div
            id="myModal5"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    Do you Want to Delete This Store
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss={this.props.storeDeleted === true ? "modal" : null}
                    onClick={this.props.onStoreDelete}
                  >
                    Yes
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-secondary"
                  >
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body text-center">
          <div
            id="myModal6"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            className="modal fade text-left"
          >
            <div role="document" className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 id="exampleModalLabel" className="modal-title">
                    Edit Store_Details
                  </h4>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    className="close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  {this.props.storeDefaultDetails !== "" ? (
                    <form
                    ref={(el) =>
                      this.props.reset === false
                        ? (this.myFormRef = el)
                        : this.myFormRef.reset()
                    }
                    >
                      <div className="form-group row">
                        <label
                          className="col-sm-3" // form-control-label
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={this.props.fieldChange}
                            type="name"
                            name="storeName"
                            placeholder={this.props.storeDefaultDetails.name}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" // form-control-label
                        >
                          Address
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={this.props.fieldChange}
                            type="name"
                            name="storeAddress"
                            placeholder={this.props.storeDefaultDetails.address}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" // form-control-label
                        >
                          Manager
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={this.props.fieldChange}
                            type="name"
                            name="storeManagerName"
                            placeholder={this.props.storeDefaultDetails.contact_name}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" // form-control-label
                        >
                          Email
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={this.props.fieldChange}
                            type="email"
                            name="storeEmail"
                            placeholder={this.props.storeDefaultDetails.mail}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" //form-control-label
                        >
                          Phone
                        </label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                onChange={this.props.fieldChange}
                                type="number"
                                placeholder={
                                  this.props.storeDefaultDetails.phone_one
                                }
                                name="storePhoneNumber"
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                onChange={this.props.fieldChange}
                                type="number"
                                name="storePhoneNumber_One"
                                placeholder={
                                  this.props.storeDefaultDetails.phone_two
                                }
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" //form-control-label"
                        >
                          Range
                        </label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                onChange={this.props.fieldChange}
                                type="string"
                                name="storeRadius"
                                placeholder={this.props.storeDefaultDetails.radius}
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col-sm-6">
                              <input
                                list="timings"
                                name="storeTiming"
                                onChange={this.props.fieldChange}
                                placeholder={
                                  this.props.storeDefaultDetails.service_hours
                                }
                                className="form-control form-control-sm"
                              />
                              <datalist id="timings">
                                <option value="9A.M to 10P.M"></option>
                                <option value="10A.M to 10P.M"></option>
                                <option value="11A.M to 11P.M"></option>
                              </datalist>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" //form-control-label"
                        >
                          Location
                        </label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                onChange={this.props.fieldChange}
                                type="number"
                                placeholder={
                                  this.props.storeDefaultDetails.longitude
                                }
                                name="storeLongitude"
                                className="form-control form-control-sm"
                              />
                            </div>
                            <div className="col-md-6">
                              <input
                                onChange={this.props.fieldChange}
                                type="number"
                                name="storeLatitude"
                                placeholder={this.props.storeDefaultDetails.latitute}
                                className="form-control form-control-sm"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" //form-control-label
                        >
                          Service
                        </label>
                        <div className="col-sm-9">
                          <div className="row">
                            <div className="col-md-6">
                              <input
                                list="status"
                                name="service_status"
                                onChange={this.props.fieldChange}
                                placeholder={
                                  this.props.storeDefaultDetails.service_status
                                }
                                className="form-control form-control-sm"
                              />
                              <datalist id="status">
                                <option value="OPEN"></option>
                                <option value="CLOSE"></option>
                              </datalist>
                            </div>
                            <div className="col-sm-6">
                              <input
                                list="reason"
                                name="status_reason"
                                onChange={this.props.fieldChange}
                                placeholder={
                                  this.props.storeDefaultDetails.status_reason
                                }
                                className="form-control form-control-sm"
                              />
                              <datalist id="reason">
                                <option value="ACTIVE"></option>
                                <option value="INACTIVE"></option>
                              </datalist>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          className="col-sm-3" // form-control-label
                        >
                          GST
                        </label>
                        <div className="col-sm-9">
                          <input
                            onChange={this.props.fieldChange}
                            type="string"
                            name="store_Gst"
                            placeholder={this.props.storeDefaultDetails.gstn}
                            className="form-control form-control-sm"
                          />
                        </div>
                      </div>
                    </form>
                  ) : null}
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss={this.props.updatedStore === true ? "modal" : null}
                    onClick={this.props.onUpdateStore}
                  >
                    Edit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-10">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <button
                  type="button"
                  data-toggle="modal"
                  data-target="#myModal"
                  className="btn btn-primary"
                  style={{position:"relative",bottom:"50%"}}
                >
                  New Store
                </button>
                <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                {/* <button
                  type="button"
                  id="closeCard1"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  className="dropdown-toggle"
                >
                  <i className="fa fa-ellipsis-v"></i>
                </button>
                <div
                  aria-labelledby="closeCard1"
                  className="dropdown-menu dropdown-menu-right has-shadow"
                >
                  <a href="#profile" className="dropdown-item remove">
                    <i className="fa fa-times"></i>Close
                  </a>
                  <a href="#profile" className="dropdown-item edit">
                    <i className="fa fa-gear"></i>Edit
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      {this.props.data
        ? this.props.data !== null || [] || ""
          ? this.props.data.map((p, index) => (
              <div key={index} className="project">
                <div className="row bg-white has-shadow">
               
                  <div className="left-col col-lg-6 d-flex align-items-center justify-content-between">
                    <div
                      onClick={() => this.props.sid(p.id)}
                      className="project-title d-flex align-items-center"
                    >
                  
                      <div className="image has-shadow">
                        <img
                          src="img/project-1.jpg"
                          alt="..."
                          className="img-fluid"
                        />
                      </div>
                      <div className="text">
                        <h3 className="h4">
                          {p.name}
                          {/* {p.storeName} */}
                        </h3>
                        <small>{p.address}</small>
                      </div>
                    </div>
                    <div className="project-date">
                      <span className="hidden-sm-down">{p.contact_name}</span>
                      <br />
                      <span className="hidden-sm-down">{p.phone_one}</span>
                      <br />
                      <span className="hidden-sm-down">{p.phone_two}</span>
                      <br />
                      <span className="hidden-sm-down">{p.mail}</span>
                      <br />
                      <span className="hidden-sm-down">{p.radius}KM</span>
                    </div>
                  </div>
                  <div className="right-col col-lg-3 d-flex align-items-center">
                    <div className="time">
                      <i className="fa fa-clock-o"></i>
                      {p.service_hours}
                      <br />
                      <i></i>
                      {p.service_status}
                      <br />
                      <i></i>
                      {p.status_reason}
                      <br/>
                      <i></i>
                      {p.gstn}
                    </div>
                    
                    {/* <div className="comments">
                <b>Total Orders: </b>
                <i></i>
                {p.report.total}
                <br />
                <b>Pending: </b>
                <i></i>
                {p.report.pending}
                <br />
                <b>Completed: </b>
                <i></i>
                {p.report.completed}
                <br />
                <b>In-Progress: </b>
                <i></i>
                {p.report.inprogress}
                <br />
                <b>Cancelled: </b>
                <i></i>
                {p.report.cancelled}
                <br />
              </div> */}
                    {/* <div className="delete">
                      <span
                        data-toggle="modal"
                        data-target="#myModal5"
                        className="fa fa-trash-o fa-lg"
                        style={{ color: "red" }}
                        onClick={() => this.props.onStoreTriggerDelete(p.id)}
                      ></span>
                    </div>
                    <div>
                      <br />
                    </div>
                    <div className="edit">
                      <span
                        data-toggle="modal"
                        data-target="#myModal6"
                        className="fa fa-pencil-square-o fa-lg"
                        style={{ color: "blue" }}
                        onClick={() => this.props.onStoreTriggerEdit(p)}
                      ></span>
                    </div>*/}
                  </div> 
                  <div className="right-col col-lg-3 d-flex align-items-center">
                    {/* <button
                      type="button"
                      // data-toggle="modal"
                      // data-target="#myModal"
                      className="btn btn-primary"
                      onClick={() => this.props.activate(p)}
                    >
                      ACTIVATE
                    </button> */}
                    <div className="card-close">
                      <div className="dropdown">
                        <button
                          type="button"
                          id="closeCard2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          className="dropdown-toggle"
                        >
                          <i className="fa fa-ellipsis-v"></i>
                        </button>
                        <div
                          aria-labelledby="closeCard2"
                          className="dropdown-menu dropdown`-menu-right has-shadow"
                        >
                          <a href="/#"
                           className="dropdown-item remove"
                           data-toggle="modal"
                           data-target="#myModal5"
                          //  className="fa fa-trash-o fa-lg"
                          //  style={{
                          //    color: "red",
                          //    position: "absolute",
                          //    right: 0,
                          //  }}
                           onClick={() =>
                             this.props.onStoreTriggerDelete(p.id)
                           }
                           >
                            <i className="fa fa-times" ></i>Delete
                          </a>
                          <a href="/#"
                           className="dropdown-item edit"
                           data-toggle="modal"
                           data-target="#myModal6"
                           onClick={() => this.props.onStoreTriggerEdit(p)}
                           >
                            <i className="fa fa-gear"
                            ></i>Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
              </div>
            ))
          : null
        : null}
    </div>
  </section>
);
            }
          }
export default StoresComponent;
