import React from "react";
import SuperAdminRootComponent from "./SuperAdminRootComponent";
import {
  // MISC,
  HOST,
  KEY,
  // AD_HOST,
  // AD_KEY,
  // BRANDID,
} from "../../Network/ApiPaths";
import Uri from "../../Network/Uri";
// import postClientDataResponse from "../../Network/PostClientDataResponse";
import getResponseClient from "../../Network/GetClientDataResponse";
// import SuperAdminReports from "../SuperAdminReports/SuperAdminReports";
// let clientHost;
// let storeFresh;
// const userCheck = window.localStorage.getItem("access");
// let storeDet = JSON.parse(userCheck);
// let storeIdValue;
// if (storeDet !== null) {
//   storeIdValue = storeDet.storeId;
// } else {
//   storeIdValue = "";
// }
class SuperAdminRootContainer extends React.Component {
  constructor(props) {

    super();
    // const userCheck = window.localStorage.getItem("access");
    // let storeDet = JSON.parse(userCheck).storeId;
    // storeIdValue = storeDet.storeId;
    // const client = window.localStorage.getItem("clientAccess");
    // storeFresh = JSON.parse(client);
    // if (storeFresh !== null) {
    // storeIdValue = storeFresh.storeId;
    //   clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    // }
    this.state = {
      state: [],
      misc: [],
      fileteredMisc: [],
      expensesData: [],
      expenseDataMonthly: [],
      salesDataMonthly: []
      // misc1: [],
      // misc2: [],
      // misc3: [],
    };
  }

  render() {

    return (

      <div>
        <SuperAdminRootComponent
          stores={this.props.stores}
          selectedStoreId={this.props.selectedStoreId}
          storeId={this.props.storeId}

          misc={this.state.misc}
          expensesData={this.state.expensesData}
          expenseDataMonthly={this.state.expenseDataMonthly}
          salesDataMonthly={this.state.salesDataMonthly}

        // misc1={this.state.misc1}
        // misc2={this.state.misc2}
        // misc3={this.state.misc3}
        />
      </div>
    );
  }

  async componentDidMount() {

    // try {
    //   setInterval(
    //     async () => {
    //       apiPath = new Uri(
    //         `${AD_HOST}MallStore/listMallStores?mallId=${BRANDID}`
    //       );
    //       getResponseClient(
    //         apiPath,
    //         AD_KEY,
    //         (onSuccessResponse) => {
    //           onSuccessResponse.result.map((p, index) => {
    //             apiPath = new Uri(p.host + MISC).addQueryParam(
    //               "store_id",
    //               p.store_id
    //             );
    //             return getResponseClient(
    //               apiPath,
    //               p.x_api_key,
    //               (onSuccessResponse2) => {
    //                 let data = [onSuccessResponse2.response[0]];
    //                 // let ref = this.state.misc.filter(
    //                 //   (q) => q.store_id === p.store_id
    //                 // );
    //                 // if (ref === data) {
    //                 //   alert("no new changes");
    //                 // } else {
    //                 //   alert("new data");
    //                 // }
    //                 this.setState({
    //                    misc: [...this.state.misc, data],
    //                   // misc: [data],
    //                 });
    //               },
    //               (onError) => {
    //                 console.log(onError.message);
    //               }
    //             );
    //           });
    //         },
    //         (onError) => {
    //           console.log(onError.message);
    //         }
    //       );
    //     },
    //     // 30000
    //     // 60000
    //     45000
    //   );
    // } catch (e) {
    //   console.log(e);
    // }
    /////////////////////////////////this is right
    // apiPath = new Uri(
    //   `${AD_HOST}MallStore/listMallStores?mallId=${BRANDID}`
    // );
    // getResponseClient(
    //   apiPath,
    //   AD_KEY,
    //   (onSuccessResponse) => {
    //     onSuccessResponse.result.map((p, index) => {
    //       apiPath = new Uri(p.host + MISC).addQueryParam(
    //         "store_id",
    //         p.store_id
    //       );
    //       return getResponseClient(
    //         apiPath,
    //         p.x_api_key,
    //         (onSuccessResponse2) => {
    //           let data = onSuccessResponse2.response[0];
    //           this.setState({
    //             misc: [...this.state.misc, data],
    //           });
    //         },
    //         (onError) => {
    //           console.log(onError.message);
    //         }
    //       );
    //     });
    //   },
    //   (onError) => {
    //     console.log(onError.message);
    //   }
    // );
    ///////////////this is test
    var apiPath = new Uri(`${HOST}Store/listAllStoresReports`); // .addQueryParam("storeid", storeIdValue);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          misc: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );


    apiPath = new Uri(`${HOST}Expenses/listAllStoresExpenseReports`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          expensesData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );

    apiPath = new Uri(`${HOST}Expenses/listAllStoresExpenseReportsMonthly`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          expenseDataMonthly: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );

    apiPath = new Uri(`${HOST}Store/listAllStoresReportsMonthly`);
    getResponseClient(
      apiPath,
      KEY,
      (onSuccessResponse) => {
        this.setState({
          salesDataMonthly: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );


  }
}
export default SuperAdminRootContainer;
