import React from 'react'
import ExpensesReportComponent from './ExpensesReportComponent';
import Uri from '../../Network/Uri';
import { CATEGORY_WISE_EXPENSES_REPORT, DAY_WISE_DATA, DELETE_EXPENSES, EXPENSE_CATEGORY_LIST, EXPENSE_CATEGORY_NO_SUB, EXPENSE_SUB_CATEGORY_LIST, ITEM_WISE_EXPENSES_REPORT, ITEM_WISE_EXPENSES_REPORT_FOR_CATEGORY, MONTH_TOTAL_COST, SUB_CATEGORY_WISE_EXPENSES_REPORT, TODAY_TOTAL_COST, UPDATE_EXPENSES, UPDATE_EXPENSE_BILL_IMAGE, WEEK_TOTAL_COST, YESTERDAY_TOTAL_COST } from '../../Network/ApiPaths';
import getResponseClient from '../../Network/GetClientDataResponse';
import postClientDataResponse from '../../Network/PostClientDataResponse';

let clientHost;
let storeFresh;
const userCheck = window.localStorage.getItem("access");
let storeDet = JSON.parse(userCheck);
let storeIdValue;
let storeAddress;
let storeName;
let role;
if (storeDet !== null) {
  storeIdValue = storeDet.storeId;
  storeAddress = storeDet.address;
} else {
  storeIdValue = "";
  storeAddress = "";
}

class ExpensesReportContainer extends React.Component {
  constructor(props) {
    super()
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeIdValue = storeDet.storeId;
      storeAddress = storeDet.address;
      role = storeDet.role;
    } else {
      storeIdValue = "";
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      storeIdValue = storeFresh.storeId;
      storeName = JSON.parse(window.localStorage.getItem("clientAccess"))
        .storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }

    let todayDate = new Date().toISOString().slice(0, 10);

    this.state = {
      todayExpenses: "",
      yesterdayExpenses: "",
      expensePage: 'default',
      fromDate: todayDate,
      toDate: todayDate,
      categoryWiseExpenses: [],
      subCategoryWiseExpenses: [],
      categoryId: "",
      categoryList: [],
      itemExpCategories: "",
      ItemSubCategoryExists: "NO",
      itemSubCategory: "",
      itemShowSubDetails: false,
      allCategories: [],
      itemSubCatData: [],
      itemWiseData: [],
      dayWiseData: [],
      weekExpenses: "",
      monthExpenses: "",
      editedBillFile: ""
    }
  }

  getExpensesItems = (event) => {
    let selectedOption = event.target.options[event.target.selectedIndex];
    this.setState({
      itemExpCategories: event.target.value,
      ItemSubCategoryExists: selectedOption.className,
      itemSubCategory: ""
    })
    if (this.state.ItemSubCategoryExists === 'YES') {
      this.setState({
        itemShowSubDetails: true,
      });
    } else {
      this.setState({
        itemShowSubDetails: false,
      });
    }
  }

  updateEditFile = (event) => {
    this.setState({
      editedBillFile: event.target.files[0]
    })
  }

  itemSetSubCategory = (event) => {
    this.setState({
      itemSubCategory: event.target.value
    });
  }

  getItemExpenseSubCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_SUB_CATEGORY_LIST)
      .addQueryParam("category_id", this.state.itemExpCategories)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          itemSubCatData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  changePage = (page) => {
    let thisDate = new Date().toISOString().slice(0, 10);
    this.setState({
      expensePage: page,
      toDate: thisDate,
      fromDate: thisDate,
    })
  }

  changeDate = (event) => {
    let date = new Date(event.target.value).toISOString().slice(0, 10);
    this.setState({
      [event.target.name]: date
    })
  }
  setCategory = (event) => {
    this.setState({
      categoryId: event.target.value
    })
  }

  getExpenseCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_LIST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          allCategories: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getTodayExpenses = () => {
    var apiPath = new Uri(clientHost + TODAY_TOTAL_COST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          todayExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getCategories = () => {
    var apiPath = new Uri(clientHost + EXPENSE_CATEGORY_NO_SUB)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          categoryList: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getCategoryWiseExpenses = () => {
    var apiPath = new Uri(clientHost + CATEGORY_WISE_EXPENSES_REPORT)
      .addQueryParam("store_id", storeFresh.storeId)
      .addQueryParam("from", this.state.fromDate)
      .addQueryParam("to", this.state.toDate)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          categoryWiseExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getSubCategoryWiseExpenses = () => {
    var apiPath = new Uri(clientHost + SUB_CATEGORY_WISE_EXPENSES_REPORT)
      .addQueryParam("category_id", this.state.categoryId)
      .addQueryParam("from", this.state.fromDate)
      .addQueryParam("to", this.state.toDate)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          subCategoryWiseExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getItemWiseExpenses = () => {
    var apiPath = new Uri(clientHost + ITEM_WISE_EXPENSES_REPORT)
      .addQueryParam("category_id", this.state.itemExpCategories)
      .addQueryParam("subcategory_id", this.state.itemSubCategory)
      .addQueryParam("store_id", storeFresh.storeId)
      .addQueryParam("from", this.state.fromDate)
      .addQueryParam("to", this.state.toDate)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          itemWiseData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getItemWiseExpensesOnlyCategory = () => {
    var apiPath = new Uri(clientHost + ITEM_WISE_EXPENSES_REPORT_FOR_CATEGORY)
      .addQueryParam("category_id", this.state.itemExpCategories)
      .addQueryParam("store_id", storeFresh.storeId)
      .addQueryParam("from", this.state.fromDate)
      .addQueryParam("to", this.state.toDate)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          itemWiseData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getDayWiseData = () => {
    var apiPath = new Uri(clientHost + DAY_WISE_DATA)
      .addQueryParam("store_id", storeFresh.storeId)
      .addQueryParam("from", this.state.fromDate)
      .addQueryParam("to", this.state.toDate)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          dayWiseData: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getYesterdayExpenses = () => {
    var apiPath = new Uri(clientHost + YESTERDAY_TOTAL_COST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          yesterdayExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getWeekExpenses = () => {
    var apiPath = new Uri(clientHost + WEEK_TOTAL_COST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          weekExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  deleteExpense = (data) => {
    var apiPath = new Uri(clientHost + DELETE_EXPENSES)
      .addQueryParam("exp_id", data)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.getDayWiseData();
        this.getTodayExpenses();
        this.getYesterdayExpenses();
        this.getMonthExpenses();
        this.getWeekExpenses();
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }


  convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  UploadFileById = (id, file, type) => {
    let img = file
    const handleFileRead = async (event) => {
      const file = img;
      const base64 = await this.convertBase64(file);
      const requestBody = {
        id: id,
        image: base64,
      };
      postClientDataResponse(
        clientHost + UPDATE_EXPENSE_BILL_IMAGE,
        storeFresh.key,
        requestBody,
        (onSuccessResponse) => {
          this.setState({
            editedBillFile: ""
          })
          if(type === 'update'){
            this.getDayWiseData();
            this.getTodayExpenses();
            this.getYesterdayExpenses();
            this.getMonthExpenses();
            this.getWeekExpenses();
          }
        },
        (onError) => {
          console.log(onError.message);
        }
      );
    };
    if (img === '' || img === null || img === undefined) {
      console.log("No files Selected")
    } else {
      handleFileRead();
    }
  }

  updateExpenses = (data) => {
    const requestBody = {
      quantity: data.quantity,
      cost: data.cost,
      id: data.id
    };

    postClientDataResponse(
      clientHost + UPDATE_EXPENSES,
      storeFresh.key,
      requestBody,
      (onSuccessResponse) => {
        if(this.state.editedBillFile !== ""){
          const uploadResult = this.UploadFileById(data.id, this.state.editedBillFile, 'update');
        }else{
        this.getDayWiseData();
        this.getTodayExpenses();
        this.getYesterdayExpenses();
        this.getMonthExpenses();
        this.getWeekExpenses();
        }
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  getMonthExpenses = () => {
    var apiPath = new Uri(clientHost + MONTH_TOTAL_COST)
      .addQueryParam("store_id", storeFresh.storeId)
    getResponseClient(
      apiPath,
      storeFresh.key,
      (onSuccessResponse) => {
        this.setState({
          monthExpenses: onSuccessResponse.result,
        });
      },
      (onError) => {
        console.log(onError.message);
      }
    );
  }

  componentDidMount() {
    this.getTodayExpenses();
    this.getYesterdayExpenses();
    this.getMonthExpenses();
    this.getWeekExpenses();
    this.getCategories();
    this.getExpenseCategories();
    this.getDayWiseData();
    this.getCategoryWiseExpenses();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.fromDate !== this.state.fromDate) {
      if (this.state.expensePage === 'category') {
        this.getCategoryWiseExpenses()
      } else if (this.state.expensePage === 'subcategory') {
        this.getSubCategoryWiseExpenses();
      } else if (this.state.expensePage === 'itemwise') {
        if (this.state.ItemSubCategoryExists === "NO") {
          this.getItemWiseExpensesOnlyCategory();
        } else {
          this.getItemWiseExpenses();
        }
      } else {
        this.getDayWiseData();
      }
    }
    if (prevState.toDate !== this.state.toDate) {
      if (this.state.expensePage === 'category') {
        this.getCategoryWiseExpenses()
      } else if (this.state.expensePage === 'subcategory') {
        this.getSubCategoryWiseExpenses();
      } else if (this.state.expensePage === 'itemwise') {
        if (this.state.ItemSubCategoryExists === "NO") {
          this.getItemWiseExpensesOnlyCategory();
        } else {
          this.getItemWiseExpenses();
        }
      } else {
        this.getDayWiseData();
      }
    }
    if (prevState.categoryId !== this.state.categoryId) {
      this.getSubCategoryWiseExpenses();
    }
    if (prevState.itemSubCategory !== this.state.itemSubCategory) {
      if(this.state.itemSubCategory !== ''){
        this.getItemWiseExpenses();
      }
    }
    if (prevState.itemExpCategories !== this.state.itemExpCategories) {
      this.getItemExpenseSubCategories();
      if (this.state.ItemSubCategoryExists === 'YES') {
        this.setState({
          itemShowSubDetails: true,
        });
      } else {
        this.getItemWiseExpensesOnlyCategory();
        this.setState({
          itemShowSubDetails: false,
        });
      }
    }
  }


  render() {
    return (
      <>
        <ExpensesReportComponent
          storeName={storeName}
          todayExpenses={this.state.todayExpenses}
          yesterdayExpenses={this.state.yesterdayExpenses}
          expensePage={this.state.expensePage}
          changePage={this.changePage}
          fromDate={this.state.fromDate}
          toDate={this.state.toDate}
          categoryWiseExpenses={this.state.categoryWiseExpenses}
          subCategoryWiseExpenses={this.state.subCategoryWiseExpenses}
          changeDate={this.changeDate}
          categoryList={this.state.categoryList}
          setCategory={this.setCategory}
          getExpensesItems={this.getExpensesItems}
          itemSubCategory={this.state.itemSubCategory}
          itemShowSubDetails={this.state.itemShowSubDetails}
          data={this.state.allCategories}
          itemSubCatData={this.state.itemSubCatData}
          itemSetSubCategory={this.itemSetSubCategory}
          itemWiseData={this.state.itemWiseData}
          dayWiseData={this.state.dayWiseData}
          monthExpenses={this.state.monthExpenses}
          weekExpenses={this.state.weekExpenses}
          deleteExpense={this.deleteExpense}
          updateExpenses={this.updateExpenses}
          updateEditFile={this.updateEditFile}
          role = {role}
        />
      </>
    )
  }
}

export default ExpensesReportContainer;
