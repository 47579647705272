import React from "react";

class ExpensesComponent extends React.Component {
  constructor(props) {
    super();

    this.state = {
      name: "",
      status: "ACTIVE",
      item_id: "",
      delete_id: "",
      unit: "",
    };
  }
  clearData(data) {
    if (data === "subcategory") {
      document.getElementById("subCatInput").value = "";
      document.getElementById("expcat").value = "";
    } else if (data === "category") {
      document.getElementById("catInput").value = "";
    } else if (data === "addItem") {
      document.getElementById("selectexpcat").value = "";
      document.getElementById("itemInput").value = "";
      document.getElementById("itemunit").value = "";
    }
  }

  handleName = (event) => {
    this.setState({
      name: event.target.value,
    });
  };

  handleStatus = (event) => {
    this.setState({
      status: event.target.checked ? "ACTIVE" : "INACTIVE",
    });
  };
  handleUnit = (event) => {
    this.setState({
      unit: event.target.value,
    });
  };

  upadteList = (value) => {
    this.setState({
      name: value.name,
      status: value.status,
      item_id: value.id,
      unit: value.unit,
    });
  };

  render() {
    return (
      <div>
        <div
          id="expensesModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Category
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={this.props.nameChange}
                      type="text"
                      placeholder="Expense Category Name"
                      className="form-control"
                      id="catInput"
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="option"
                          type="checkbox"
                          value=""
                          name="optionChecked"
                          checked={this.props.optionChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="option"> Active</label>
                      </div>
                      <div>
                        <input
                          id="subcat"
                          type="checkbox"
                          value=""
                          className="mr-1"
                          name="subcatChecked"
                          checked={this.props.subcatChecked}
                          onChange={this.props.handleCheckboxChange}
                        />
                        <label htmlFor="subcat">Sub Category</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.add}
                    data-dismiss={this.props.addedCat === true ? "" : "modal"}
                  >
                    <input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Expense Items */}
        <div
          id="itemModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Item
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Select Expense Category</label>
                    <br />
                    <select
                      onChange={this.props.get_set_SubCategory}
                      className="form-control"
                      name="expcat"
                      id="selectexpcat"
                      value={this.props.selectedCategoryId}
                    >
                      <option value="" disabled>
                        Select Category
                      </option>
                      {this.props.data && this.props.data.length > 0 ? (
                        this.props.data.map((item, index) => (
                          <option
                            key={index}
                            className={item.subcategory}
                            value={item.id}
                          >
                            {item.name}
                          </option>
                        ))
                      ) : (
                        <option value="" disabled>
                          No Details Found
                        </option>
                      )}
                    </select>
                  </div>
                  {this.props.showSubDetails ? (
                    <div className="form-group">
                      <label>Select Expense Sub Category</label>
                      <br />
                      <select
                        onChange={this.props.setSubCategory}
                        value={this.props.subCategory}
                        className="form-control"
                        name="expsubcat"
                        id="expsubcat"
                      >
                        <option value="" selected disabled>
                          Select Sub Category
                        </option>
                        {this.props.SubCatdata !== "" &&
                        this.props.SubCatdata !== null &&
                        this.props.SubCatdata !== undefined ? (
                          this.props.SubCatdata.map((item, index) => {
                            return (
                              <option
                                key={index}
                                style={{ color: "black" }}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })
                        ) : (
                          <option value="" disabled>
                            No Sub Category Found
                          </option>
                        )}
                      </select>
                    </div>
                  ) : null}
                  <div className="form-group">
                    <label>Item Name</label>
                    <input
                      onChange={this.props.onItemNameChange}
                      type="text"
                      placeholder="Enter Item Name"
                      className="form-control"
                      id="itemInput"
                    />
                  </div>
                  {/* <div className="form-group">
                    <label>Select Item Unit</label><br />
                    <select onChange={this.props.setUnit} className="form-control" name="expunit" id="expunit">
                      <option value="" selected disabled>Select Unit</option>
                      <option style={{ color: "black" }} value="kg">Kg</option>
                      <option style={{ color: "black" }} value="grams">grams</option>
                      <option style={{ color: "black" }} value="ltr">Litre</option>
                      <option style={{ color: "black" }} value="pkt">Pkt</option>
                    </select>
                  </div> */}
                  <div className="form-group">
                    <label>Enter Unit</label>
                    <input
                      onChange={this.props.setUnit}
                      type="text"
                      placeholder="Enter unit"
                      className="form-control"
                      id="itemunit"
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="selectedoptionItem"
                          type="checkbox"
                          value=""
                          name="ItemChecked"
                          checked={this.props.ItemChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="selectedoptionItem"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.addItemData}
                    data-dismiss={this.props.addedItem === true ? "" : "modal"}
                  >
                    <input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          id="editExpenseCategory"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Update Expense Items
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      onChange={this.handleName}
                      placeholder="Expense Item"
                      className="form-control"
                      id="updateInput"
                      value={this.state.name}
                    />
                  </div>
                  <div className="form-group">
                    <label>Select Item Unit</label>
                    <br />
                    <select
                      onChange={this.handleUnit}
                      value={this.state.unit}
                      className="form-control"
                      name="updateexpunit"
                      id="updateexpunit"
                    >
                      <option value="" disabled>
                        Select Unit
                      </option>
                      <option value="kg">Kg</option>
                      <option value="grams">grams</option>
                      <option value="ltr">Litre</option>
                      <option value="pkt">Pkt</option>
                    </select>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="updateoption"
                          onChange={this.handleStatus}
                          type="checkbox"
                          value=""
                          name="udpateoptionChecked"
                          className="mr-1"
                          checked={
                            this.state.status === "ACTIVE" ? true : false
                          }
                        />
                        <label htmlFor="updateoption"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.updateItems(this.state);
                    }}
                    data-dismiss="modal"
                  >
                    <input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          id="deleteExpenseCategory"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div
                className="modal-header"
                style={{ justifyContent: "center" }}
              >
                <h4 id="exampleModalLabel" className="modal-title">
                  Are you Sure
                </h4>
              </div>
              <div className="modal-body">
                <div
                  style={{ display: "flex", justifyContent: "space-around" }}
                >
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      this.props.deleteItem(this.state.delete_id);
                    }}
                    data-dismiss="modal"
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-success"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <MainDropdown data={this.props} /> */}

        <div
          className="card-header d-flex align-items-center"
          style={{ justifyContent: "space-between", flexWrap: "wrap", gap: 10 }}
        >
          <h3 className="h4">Expenses</h3>
          <div>
            <MainDropdown data={this.props} />
          </div>
          <div>
            <button
              type="button"
              data-toggle="modal"
              data-target="#itemModel"
              className="btn btn-primary"
              onClick={() => {
                this.clearData("addItem");
              }}
            >
              Add Expense Item
            </button>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive expenseTable">
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>SL.No</th>
                  <th>Name</th>
                  <th>Unit</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.expenseItemData
                  ? this.props.expenseItemData > 0 ||
                    this.props.expenseItemData !== null ||
                    this.props.expenseItemData !== undefined
                    ? this.props.expenseItemData.map((value, index) => (
                        <tr key={index}>
                          <th scope="row">{index + 1}</th>
                          <td>{value.name}</td>
                          <td>{value.unit}</td>
                          <td style={{ textAlign: "center" }}>
                            {value.status}
                          </td>
                          <td
                            id={value.id}
                            data-toggle="modal"
                            onClick={() => {
                              this.upadteList(value);
                            }}
                            data-target="#editExpenseCategory"
                            className="fa fa-edit fa-lg"
                          ></td>
                          <td
                            id={value.id}
                            data-toggle="modal"
                            data-target="#deleteExpenseCategory"
                            className="fa fa-trash-o fa-lg"
                            onClick={() =>
                              this.setState({ delete_id: value.id })
                            }
                          ></td>
                        </tr>
                      ))
                    : this.props.expenseItemData
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

export function MainDropdown(props) {
  const {
    getExpensesItems,
    data,
    itemShowSubDetails,
    itemSetSubCategory,
    itemSubCatData,
    itemExpCategories,
    itemSubCategory,
  } = props.data;
  return (
    <div className="d-flex" style={{ gap: 15 }}>
      <div className="d-flex">
        <select
          onChange={getExpensesItems}
          value={itemExpCategories}
          className="form-control"
          name="expItems"
          id="expItems"
        >
          <option value="" disabled>
            Select Category
          </option>
          {data !== "" && data !== null && data !== undefined ? (
            data.map((item, index) => (
              <option
                className={item.subcategory}
                key={index}
                style={{ color: "black" }}
                value={item.id}
              >
                {item.name}
              </option>
            ))
          ) : (
            <option value="" disabled>
              No Details Found
            </option>
          )}
        </select>
      </div>
      {itemShowSubDetails ? (
        <div>
          <select
            onChange={itemSetSubCategory}
            value={itemSubCategory}
            className="form-control"
            name="itemexpsubcat"
            id="itemexpsubcat"
          >
            <option value="" disabled>
              Select Sub Category
            </option>
            {itemSubCatData !== "" &&
            itemSubCatData !== null &&
            itemSubCatData !== undefined ? (
              itemSubCatData.map((item, index) => (
                <option key={index} style={{ color: "black" }} value={item.id}>
                  {item.name}
                </option>
              ))
            ) : (
              <option value="" disabled>
                No Sub Category Found
              </option>
            )}
          </select>
        </div>
      ) : null}
    </div>
  );
}

export default ExpensesComponent;
