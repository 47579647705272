import React from "react";

class ExpenseSubCategoryComponent extends React.Component {
  constructor(props) {
    super()
    this.state = ({
      name: "",
      status: "ACTIVE",
      subCat_id: "",
      delete_id: ""
    })
  }
  clearData(data) {
    if (data === 'subcategory') {
      document.getElementById('subCatInput').value = "";
      document.getElementById('expcat').value = "";
    } else if (data === 'category') {
      document.getElementById('catInput').value = "";
    } else if (data === "addItem") {
      document.getElementById("selectexpcat").value = "";
      document.getElementById("itemInput").value = "";
      document.getElementById("expunit").value = "";
    }
  }


  handleName = (event) => {
    this.setState({
      name: event.target.value
    })
  }

  handleStatus = (event) => {
    this.setState({
      status: event.target.checked ? "ACTIVE" : "INACTIVE"
    })
  }

  upadteList = (value) => {
    this.setState({
      name: value.name,
      status: value.status,
      subCat_id: value.id,
    })
  }


  render() {
    return (
      <div>
        <div
          id="expensesModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Category
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={this.props.nameChange}
                      type="text"
                      placeholder="Expense Category Name"
                      className="form-control"
                      id="catInput"
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="option"
                          type="checkbox"
                          value=""
                          name="optionChecked"
                          checked={this.props.optionChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="option"> Active</label>
                      </div>
                      <div>
                        <input
                          id="subcat"
                          type="checkbox"
                          value=""
                          className="mr-1"
                          name="subcatChecked"
                          checked={this.props.subcatChecked}
                          onChange={this.props.handleCheckboxChange}
                        />
                        <label htmlFor="subcat">Sub Category</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.add} data-dismiss={
                      this.props.addedCat === true ? "" : "modal"
                    }
                  ><input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Expenses Sub Category */}
        {/* <div
          id="subCatModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Sub Category
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Select Expense Category</label><br />
                    <select onChange={this.props.optionChange} className="form-control" name="expcat" id="expcat">
                      <option value="" selected disabled>Select Category</option>
                      {
                        this.props.data !== '' && this.props.data !== null && this.props.data !== undefined ? this.props.data.map((item, index) => {
                          return (<option key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                        }) : <option value="" disabled>No Details Found</option>
                      }
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={this.props.onSubNameChange}
                      type="text"
                      placeholder="Expense Sub Category Name"
                      className="form-control"
                      id="subCatInput"
                    />
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="selectedoption"
                          type="checkbox"
                          value=""
                          name="subCatOptionChecked"
                          checked={this.props.subCatOptionChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="selectedoption"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.addSubCategory} data-dismiss={
                      this.props.addedSubCat === true ? "" : "modal"
                    }
                  ><input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}
        {/* Expenses Sub Category */}
        <div
          id="subCatModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Sub Category
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Select Expense Category</label><br />
                    <select onChange={this.props.optionChange} className="form-control" name="expcat" id="expcat">
                      <option value="" selected disabled>Select Category</option>
                      {
                        this.props.SubCategoryData !== '' && this.props.SubCategoryData !== null && this.props.SubCategoryData !== undefined ? this.props.SubCategoryData.map((item, index) => {
                          return (<option key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                        }) : <option value="" disabled>No Details Found</option>
                      }
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      onChange={this.props.onSubNameChange}
                      type="text"
                      placeholder="Expense Sub Category Name"
                      className="form-control"
                      id="subCatInput"
                    />
                  </div>

                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="selectedoption"
                          type="checkbox"
                          value=""
                          name="subCatOptionChecked"
                          checked={this.props.subCatOptionChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="selectedoption"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.addSubCategory} data-dismiss={
                      this.props.addedSubCat === true ? "" : "modal"
                    }
                  ><input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div
          id="deleteExpenseCategory"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header" style={{ justifyContent: "center" }}>
                <h4 id="exampleModalLabel" className="modal-title">
                  Are you Sure
                </h4>

              </div>
              <div className="modal-body">
                <span style={{ textAlign: "center" }}>
                  This will delete All Items in this Sub Category
                </span>
                <div style={{ display: "flex", justifyContent: "space-around" }}>
                  <button className="btn btn-primary" onClick={() => { this.props.deleteSubCategory(this.state.delete_id) }} data-dismiss="modal">
                    Delete
                  </button>
                  <button
                    type="button"
                    data-dismiss="modal"
                    className="btn btn-success"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div
          id="editExpenseCategory"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Update Expense Sub Category
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label>Name</label>
                    <input
                      type="text"
                      onChange={this.handleName}
                      placeholder="Expense Sub Category Name"
                      className="form-control"
                      id="updateInput"
                      value={this.state.name}
                    />
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="updateoption"
                          onChange={this.handleStatus}
                          type="checkbox"
                          value=""
                          name="udpateoptionChecked"
                          className="mr-1"
                          checked={this.state.status === "ACTIVE" ? true : false}
                        />
                        <label htmlFor="updateoption"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={(e) => { e.preventDefault(); this.props.updateSubCategory(this.state) }} data-dismiss="modal"
                  ><input
                      type="submit"
                      value="Update"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Expense Items */}
        <div
          id="itemModel"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
          className="modal fade text-left"
        >
          <div role="document" className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 id="exampleModalLabel" className="modal-title">
                  Add Expense Item
                </h4>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  className="close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form
                // ref={(el) =>
                //   this.props.reset === false
                //     ? (this.myFormRef = el)
                //     : this.myFormRef.reset()
                // }
                >
                  <div className="form-group">
                    <label>Select Expense Category</label><br />
                    <select onChange={this.props.get_set_SubCategory} className="form-control" name="expcat" id="selectexpcat">
                      <option value="" selected disabled>Select Category</option>
                      {
                        this.props.data !== '' && this.props.data !== null && this.props.data !== undefined ? this.props.data.map((item, index) => {
                          return (<option className={item.subcategory} key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                        }) : <option value="" disabled>No Details Found</option>
                      }
                    </select>
                  </div>
                  {
                    this.props.showSubDetails ?
                      (
                        <div className="form-group">
                          <label>Select Expense Sub Category</label><br />
                          <select onChange={this.props.setSubCategory} className="form-control" name="expsubcat" id="expsubcat">
                            <option value="" selected disabled>Select Sub Category</option>
                            {
                              this.props.SubCatdata !== '' && this.props.SubCatdata !== null && this.props.SubCatdata !== undefined ? (this.props.SubCatdata.map((item, index) => {
                                return (<option key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                              })) : <option value="" disabled>No Sub Category Found</option>
                            }
                          </select>
                        </div>) : null
                  }
                  <div className="form-group">
                    <label>Item Name</label>
                    <input
                      onChange={this.props.onItemNameChange}
                      type="text"
                      placeholder="Enter Item Name"
                      className="form-control"
                      id="itemInput"
                    />
                  </div>
                  <div className="form-group">
                    <label>Select Item Unit</label><br />
                    <select onChange={this.props.setUnit} className="form-control" name="expunit" id="expunit">
                      <option value="" selected disabled>Select Unit</option>
                      <option style={{ color: "black" }} value="kg">Kg</option>
                      <option style={{ color: "black" }} value="grams">grams</option>
                      <option style={{ color: "black" }} value="ltr">Litre</option>
                      <option style={{ color: "black" }} value="pkt">Pkt</option>
                    </select>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-9 d-flex">
                      <div className="mr-3">
                        <input
                          id="selectedoptionItem"
                          type="checkbox"
                          value=""
                          name="ItemChecked"
                          checked={this.props.ItemChecked}
                          onChange={this.props.handleCheckboxChange}
                          className="mr-1"
                        />
                        <label htmlFor="selectedoptionItem"> Active</label>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ display: "inline-block" }}
                    className="form-group"
                    onClick={this.props.addItemData} data-dismiss={
                      this.props.addedItem === true ? "" : "modal"
                    }
                  ><input
                      type="submit"
                      value="ADD"
                      className="btn btn-primary"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="expenses-btn-container">
          <div className="d-flex">
            <select onChange={this.props.getSubCategoryData} style={{ cursor: "pointer" }} className="form-control" name="expcat" id="selectexpcat">
              <option value="" selected disabled>Select Category</option>
              {
                this.props.SubCategoryData !== '' && this.props.SubCategoryData !== null && this.props.SubCategoryData !== undefined ? this.props.SubCategoryData.map((item, index) => {
                  return (<option className={item.subcategory} key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                }) : <option value="" disabled>No Details Found</option>
              }
            </select>
          </div>
          <button
            type="button"
            data-toggle="modal"
            data-target="#subCatModel"
            className="btn btn-primary"
            onClick={() => { this.clearData("subcategory") }}
          >
            Add Sub Category
          </button>
        </div> */}
        <div className="card-header d-flex align-items-center subcat-heading" style={{justifyContent: 'space-between', flexWrap: 'wrap', gap: 10}}>
          <h3 className="h4">Expense Sub Categories</h3>
          <div className="d-flex">
            <select onChange={this.props.getSubCategoryData} style={{ cursor: "pointer" }} className="form-control" name="expcat" id="selectexpcat">
              <option value="" selected disabled>Select Category</option>
              {
                this.props.SubCategoryData !== '' && this.props.SubCategoryData !== null && this.props.SubCategoryData !== undefined ? this.props.SubCategoryData.map((item, index) => {
                  return (<option className={item.subcategory} key={index} style={{ color: "black" }} value={item.id}>{item.name}</option>)
                }) : <option value="" disabled>No Details Found</option>
              }
            </select>
          </div>
          <button
            type="button"
            data-toggle="modal"
            data-target="#subCatModel"
            className="btn btn-primary"
            onClick={() => { this.clearData("subcategory") }}
          >
            Add Sub Category
          </button>
        </div>
        <div className="card-body">
          <div className="table-responsive expenseTable">
            <table className="table" style={{ textAlign: "center" }}>
              <thead>
                <tr>
                  <th>SL.No</th>
                  <th>Name</th>
                  <th>Expense Item Count</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {this.props.expSubCategoryData
                  ?
                  this.props.expSubCategoryData !== null &&
                    this.props.expSubCategoryData !== undefined
                    ? this.props.expSubCategoryData.map((value, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{value.name}</td>
                        <td>{value.itemcount}</td>
                        <td style={{ textAlign: "center" }}>{value.status}</td>
                        <td
                          id={value.id}
                          data-toggle="modal"
                          onClick={() => { this.upadteList(value) }}
                          data-target="#editExpenseCategory"
                          className="fa fa-edit fa-lg"
                        >

                        </td>
                        <td
                          id={value.id}
                          data-toggle="modal"
                          data-target="#deleteExpenseCategory"
                          className="fa fa-trash-o fa-lg"
                          onClick={() => this.setState({ delete_id: value.id })}
                        >

                        </td>
                        {/* <td
                                  onClick={() =>
                                    this.props.initiatedEditing(p)
                                  }
                                  data-toggle="modal"
                                  data-target="#myModal2"
                                  className="fa fa-edit fa-lg"
                                >
                                  
                                  </td> */}
                        {/* <td
                                  onClick={() =>
                                    this.props.onCatTriggerDelete(value.id)
                                  }
                                  data-toggle="modal"
                                  data-target="#myModal3"
                                  className="fa fa-trash-o fa-lg"
                                >

                                </td> */}
                      </tr>
                    ))
                    : this.props.expSubCategoryData
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}


export default ExpenseSubCategoryComponent;