import React, { Component } from "react";
import { Bar } from "react-chartjs-2";
class ExpensesChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: props.chartData,
    };
  }
  render() {
    let finalAmount = this.props.chartData
      ? this.props.chartData
        .filter((p) => p.store.status_reason === "ACTIVE")
        .map((p) => parseFloat(p.report.final_amount).toFixed(5))
      : null;
    let storeName = this.props.chartData
      ? this.props.chartData
        .filter((p) => p.store.status_reason === "ACTIVE")
        .map((p) => p.store.name)
      : null;
    const data1 = (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient2 = ctx.createLinearGradient(146.0, 0.0, 154.0, 300.0);
      gradient2.addColorStop(0, "rgba(240, 99, 81, 0.85)");
      gradient2.addColorStop(1, "rgba(240, 99, 81, 0.85)");
      return {
        labels: storeName
          ? storeName
          : ["January", "February", "March", "April", "May", "June", "July"],
        datasets: [
          {
            label: "Expenses in Rupees",
            backgroundColor: [
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
            ],
            hoverBackgroundColor: [
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
            ],
            borderColor: [
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
              gradient2,
            ],
            borderWidth: 1,
            data: finalAmount ? finalAmount : [65, 59, 80, 81, 56, 55, 40],
          },
        ],
      };
    };
    return (
      <div className="chart">
        <Bar
          data={data1}
          options={{
            legend: { labels: { fontColor: "#777", fontSize: 12 } },
            scales: {
              xAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  gridLines: {
                    color: "#eee",
                  },
                  ticks: {
                    suggestedMin: 0,
                  },
                },
              ],
            },
          }}
        />
      </div>
    );
  }
}

export default ExpensesChart;
