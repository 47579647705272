import React from "react";
import Footer from "./Footer";
// import Home from "../Components/Home";
import Reports from "../Components/Reports/Reports";
import TakeOrder from "../Components/TakeOrder/TakeOrder";
import Orders from "../Components/Orders/Orders";
import ExpensesContainer from "../Components/ExpensesStore/ExpensesContainer";
import ExpensesReportcontainer from "./Expenses Reports/ExpensesReportcontainer";
import Expenses from "../Components/Expenses";
import logo from "../images/logo.png";
class ShopDash extends React.Component {
  constructor(props) {
    super();
    this.state = {
      home: true,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    };
  }
  showHome = (e) => {
    e.preventDefault();
    this.setState({
      home: true,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    });
  };
  showTakeOrder = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: true,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    });
  };
  showOrders = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: true,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    });
  };
  showExpenses = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: false,
      expensesMain: true,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    });
  };

  showExpenseCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: true,
      expenseSubCat: false,
    });
  };
  showExpenseSubCategories = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: true,
    });
  };
  showExpenseItems = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: false,
      expensesItems: true,
      expenseCat: false,
      expenseSubCat: false,
    });
  };
  showExpenseReport = (e) => {
    e.preventDefault();
    this.setState({
      home: false,
      takeOrder: false,
      orders: false,
      expensesMain: false,
      expenseReport: true,
      expensesItems: false,
      expenseCat: false,
      expenseSubCat: false,
    });
  };
  render() {
    const userCheck = window.localStorage.getItem("access");
    return (
      <div className="page-content d-flex align-items-stretch">
        <nav className="side-navbar">
          <div className="sidebar-header d-flex align-items-center">
            <div className="avatar">
              <img src={logo} alt="..." className="img-fluid rounded-circle" />
            </div>
            <div className="title">
              <h1 className="h4">{JSON.parse(userCheck).name}</h1>
              <p>{JSON.parse(userCheck).role}</p>
            </div>
          </div>
          <ul className="list-unstyled">
            <li
              onClick={this.showHome}
              className={this.state.home ? "active" : null}
            >
              <a href="#/">
                <i className="icon-home"></i>Home
              </a>
            </li>
            <li
              onClick={this.showTakeOrder}
              className={this.state.takeOrder ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Take Order
              </a>
            </li>
            <li
              onClick={this.showOrders}
              className={this.state.orders ? "active" : null}
            >
              <a href="#/">
                <i className="icon-grid"></i>Orders
              </a>
            </li>
            <li>
              <a
                href="#exampledropdownDropdown5"
                aria-expanded="false"
                data-toggle="collapse"
              >
                <i className="fa fa-money"></i>Expenses
              </a>
              <ul
                id="exampledropdownDropdown5"
                className="collapse list-unstyled "
              >
                <li
                  onClick={this.showExpenses}
                  className={this.state.expensesMain ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-money"></i>Add Expenses
                  </a>
                </li>
                <li
                  onClick={this.showExpenseItems}
                  className={this.state.expensesItems ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Add Expense Items
                  </a>
                </li>
                {/* <li
                  onClick={this.showExpenseCategories}
                  className={this.state.expenseCat ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Categories
                  </a>
                </li>
                <li
                  onClick={this.showExpenseSubCategories}
                  className={this.state.expenseSubCat ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>SubCategories
                  </a>
                </li>
                <li
                  onClick={this.showExpenseItems}
                  className={this.state.expensesItems ? "active" : null}
                >
                  <a href="#/">
                    <i className="fa fa-list-alt"></i>Items
                  </a>
                </li> */}
                <li
                  onClick={this.showExpenseReport}
                  className={this.state.expenseReport ? "active" : null}
                >
                  <a href="#/">
                    <i className="icon-padnote"></i>Expense Reports
                  </a>
                </li>
              </ul>
            </li>
          </ul>
          <span className="heading"></span>
        </nav>
        <div className="content-inner">
          {this.state.home ? <Reports /> : null}
          {this.state.takeOrder ? <TakeOrder /> : null}
          {this.state.orders ? <Orders /> : null}
          {this.state.expenseReport ? <ExpensesReportcontainer /> : null}
          {this.state.expensesMain ? <ExpensesContainer /> : null}
          {this.state.expensesItems ? (
            <Expenses
              expenses={this.state.expensesItems}
              expenseCat={this.state.expenseCat}
              expenseSubCat={this.state.expenseSubCat}
            />
          ) : null}
          {/* {this.state.expenseCat ? <Expenses expenses={this.state.expensesItems} expenseCat={this.state.expenseCat} expenseSubCat={this.state.expenseSubCat} /> : null} */}
          {/* {this.state.expenseSubCat ? <Expenses expenses={this.state.expensesItems} expenseCat={this.state.expenseCat} expenseSubCat={this.state.expenseSubCat} /> : null} */}
          <Footer />
        </div>
      </div>
    );
  }
}
export default ShopDash;
