import React, { useRef } from "react";
import Card from "../Card/Card";
import { Col, Row } from "react-bootstrap";
import { HOST_PREFIX } from "../../Network/ApiPaths";
import { useState } from "react";
import ReactToPrint from "react-to-print";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { CURRENCY } from "../../Network/ApiPaths";

let storeFresh;
let clientHost;
let storeDet;
let storeAddress;
let storeName;

class ExpensesReportComponent extends React.Component {
  constructor(props) {
    super();
    const userCheck = window.localStorage.getItem("access");
    storeDet = JSON.parse(userCheck);
    if (storeDet !== null) {
      storeAddress = storeDet.address;
    } else {
      storeAddress = "";
    }
    const client = window.localStorage.getItem("clientAccess");
    storeFresh = JSON.parse(client);
    if (storeFresh !== null) {
      // storeName = storeFresh.storeName;
      storeName = JSON.parse(
        window.localStorage.getItem("clientAccess")
      ).storeName;
      clientHost = JSON.parse(window.localStorage.getItem("clientAccess")).host;
    }
  }

  pdfCheck = (data, id, name) => {
    if (data) {
      const doc = new jsPDF();
      doc.autoTable({ html: `#${id}` });
      doc.save(
        `${storeName}_${name}_${this.props.fromDate}to${this.props.toDate}.pdf`
      );
    } else {
      alert("No data");
    }
  };

  render() {
    return (
      <>
        <Card
          id="expensesActivity"
          stats="Data information certified"
          statsIcon="fa fa-check"
          content={
            <div>
            <h3
            style={{
              textAlign: "left",
              padding: "1%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 10,
            }}
          ><ExpenseButtons data={this.props} /></h3>
            <h3> {this.props.storeName} &nbsp; Expenses Report</h3>
              
            </div>
          }
        />

        {this.props.expensePage === "itemwise" ? (
          <ItemWise pdfCheck={this.pdfCheck} data={this.props} />
        ) : this.props.expensePage === "category" ? (
          <CategoryWise pdfCheck={this.pdfCheck} data={this.props} />
        ) : this.props.expensePage === "subcategory" ? (
          <SubCategoryWise pdfCheck={this.pdfCheck} data={this.props} />
        ) : (
          <ExpensesDetails data={this.props} pdfCheck={this.pdfCheck} />
        )}
      </>
    );
  }
}

function ExpensesDetails(props) {
  const { todayExpenses, yesterdayExpenses, monthExpenses, weekExpenses } =
    props.data;
  let totalCost =
    todayExpenses !== "" &&
    todayExpenses !== null &&
    todayExpenses !== undefined
      ? todayExpenses
      : "00.00";
  let yesterdayCost =
    yesterdayExpenses !== "" &&
    yesterdayExpenses !== null &&
    yesterdayExpenses !== undefined
      ? yesterdayExpenses
      : "00.00";
  let monthCost =
    monthExpenses !== "" &&
    monthExpenses !== null &&
    monthExpenses !== undefined
      ? monthExpenses
      : "00.00";
  let weekCost =
    weekExpenses !== "" && weekExpenses !== null && weekExpenses !== undefined
      ? weekExpenses
      : "00.00";
  return (
    <>
      <div className="expense-container">
        <h2>
          Today <hr style={{ margin: 6 }} />
          <span>
            {" "}
            <i className="fa fa-inr"></i> {parseFloat(totalCost).toFixed(2)}
          </span>
        </h2>
        <h2>
          Yesterday <hr style={{ margin: 6 }} />
          <span>
            {" "}
            <i className="fa fa-inr"></i> {parseFloat(yesterdayCost).toFixed(2)}
          </span>
        </h2>
        <h2>
          Last Week <hr style={{ margin: 6 }} />{" "}
          <span>
            {" "}
            <i className="fa fa-inr"></i> {parseFloat(weekCost).toFixed(2)}
          </span>{" "}
        </h2>
        <h2>
          Last Month
          <hr style={{ margin: 6 }} />
          <span>
            {" "}
            <i className="fa fa-inr"></i> {parseFloat(monthCost).toFixed(2)}
          </span>
        </h2>
      </div>
      <ExpenseReportsData data={props.data} pdfCheck={props.pdfCheck} />
    </>
  );
}

function ExpenseButtons(props) {
  const { changePage } = props.data;
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        gap: 10,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <button
        className="btn btn-primary"
        onClick={() => changePage("category")}
      >
        Category Wise
      </button>
      <button
        className="btn btn-primary"
        onClick={() => changePage("subcategory")}
      >
        Sub Category Wise
      </button>
      <button
        className="btn btn-primary"
        onClick={() => changePage("itemwise")}
      >
        Item Wise
      </button>
    </div>
  );
}

function ExpenseReportsData(props) {
  const {
    dayWiseData,
    changeDate,
    updateEditFile,
    updateExpenses,
    deleteExpense,
    role,
    fromDate,
    toDate,
  } = props.data;
  let todayDate = new Date().toISOString().slice(0, 10);
  let total_Cost =
    dayWiseData.length > 1
      ? parseFloat(
          dayWiseData[dayWiseData.length - 1].subcategory_name
        ).toFixed(2)
      : "00.00";

  const [values, setValues] = useState({
    name: "",
    quantity: "",
    unit: "",
    cost: "",
    total_cost: "",
    bill_image: "",
    id: "",
    delete_id: "",
  });

  function upadteList(data) {
    setValues({
      ...values,
      name: data.name,
      quantity: data.quantity,
      unit: data.unit,
      cost: data.cost,
      total_cost: data.total_cost,
      bill_image: data.bill_image,
      id: data.id,
    });
  }

  const compenetRef = useRef(null);

  function handleCost(e) {
    setValues({ ...values, cost: e.target.value });
  }
  function handleQuantity(e) {
    setValues({ ...values, quantity: e.target.value });
  }

  function calcCost() {
    setValues({
      ...values,
      total_cost: parseFloat(values.cost) * parseFloat(values.quantity),
    });
  }

  return (
    <section className="client no-padding-top">
      <div
        id="editExpense"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 id="exampleModalLabel" className="modal-title">
                Update Expense
              </h4>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                className="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Name</label>
                  <input
                    type="text"
                    // onChange={this.handleName}
                    placeholder="Expense Item"
                    className="form-control"
                    id="updateInput"
                    value={values.name}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Quantity</label>
                  <input
                    type="text"
                    maxLength={6}
                    onKeyPress={(event) => {
                      if (
                        !/^[0-9]*\.?[0-9]*$/.test(event.key) ||
                        (event.target.value.includes(".") && event.key === ".")
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleQuantity}
                    onKeyUp={calcCost}
                    placeholder="Quantity"
                    className="form-control"
                    id="updateInput"
                    value={values.quantity}
                  />
                </div>
                <div className="form-group">
                  <label>Unit</label>
                  <input
                    type="text"
                    // onChange={this.handleQuantity}
                    placeholder="Unit"
                    className="form-control"
                    id="updateInput"
                    value={values.unit}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Cost</label>
                  <input
                    type="text"
                    maxLength={10}
                    onKeyPress={(event) => {
                      if (
                        !/^[0-9]*\.?[0-9]*$/.test(event.key) ||
                        (event.target.value.includes(".") && event.key === ".")
                      ) {
                        event.preventDefault();
                      }
                    }}
                    onChange={handleCost}
                    onKeyUp={calcCost}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                    value={values.cost}
                  />
                </div>
                <div className="form-group">
                  <label>Total Cost</label>
                  <input
                    type="text"
                    // onChange={this.handleCost}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                    value={parseFloat(values.total_cost).toFixed(2)}
                    disabled={true}
                  />
                </div>
                <div className="form-group">
                  <label>Bill Image</label>
                  <input
                    type="file"
                    onChange={updateEditFile}
                    placeholder="Cost"
                    className="form-control"
                    id="updateInput"
                  />
                  <a
                    href={`${HOST_PREFIX}/images/expenses/${values.id}/${values.bill_image}`}
                    target="_blank"
                  >
                    {values.bill_image}
                  </a>
                </div>
                <div
                  style={{ display: "inline-block" }}
                  className="form-group"
                  onClick={(e) => {
                    e.preventDefault();
                    updateExpenses(values);
                  }}
                  data-dismiss="modal"
                >
                  <input
                    type="submit"
                    value="Update"
                    className="btn btn-primary"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="deleteExpense"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
        className="modal fade text-left"
      >
        <div role="document" className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header" style={{ justifyContent: "center" }}>
              <h4 id="exampleModalLabel" className="modal-title">
                Are you Sure
              </h4>
            </div>
            <div className="modal-body">
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    deleteExpense(values.delete_id);
                  }}
                  data-dismiss="modal"
                >
                  Delete
                </button>
                <button
                  type="button"
                  data-dismiss="modal"
                  className="btn btn-success"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <div
                  id="closeCard4"
                  aria-haspopup="true"
                  className="dropdown-toggle"
                >
                  <Col md={3}>
                    <Card ctAllIcons content={<></>} />
                  </Col>
                </div>
              </div>
            </div>
            <div
              className="card-header d-flex align-items-center"
              style={{ justifyContent: "center", flexWrap: "wrap", gap: 30 }}
            >
              <h3 className="h4">Day Wise Expense</h3>
              <h3>
                Total Expense: &nbsp;{" "}
                <span style={{ color: "green" }}>
                  <i className="fa fa-inr"> </i>&nbsp;&nbsp;{total_Cost}
                </span>
              </h3>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="fromDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="toDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div>
                  <a
                    href="#/"
                    onClick={() => {
                      props.pdfCheck(
                        dayWiseData,
                        "my-table1",
                        "DayWiseExpensesReports"
                      );
                    }}
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </div>
                <div style={{ zIndex: 3 }}>
                  <ReactToPrint
                    trigger={() => <a href="#/">Print</a>}
                    content={() => compenetRef.current}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div style={{ height: "400px", overflow: "auto" }}>
                  <table
                    className="table table-striped table-sm"
                    id="my-table1"
                  >
                    <thead>
                      <tr>
                        <th>SL.No</th>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th>Name</th>
                        <th>Qunatity</th>
                        <th>unit</th>
                        <th>cost</th>
                        <th>total Cost</th>
                        <th>Bill Image</th>
                        <th>Created At</th>
                        {role === "SUPER_ADMIN" ? <th>Actions</th> : null}
                      </tr>
                    </thead>
                    <tbody>
                      {dayWiseData ? (
                        dayWiseData.slice(0, -1).map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.category_name}</td>
                              <td>
                                {item.subcategory_name === "No Subcategory" ? (
                                  <span style={{ color: "red" }}>
                                    No Subcategory
                                  </span>
                                ) : (
                                  item.subcategory_name
                                )}
                              </td>
                              <td>{item.name}</td>
                              <td>{item.quantity}</td>
                              <td>{item.unit}</td>
                              <td>{item.cost}</td>
                              <td>
                                {item.total_cost !== null &&
                                item.total_cost !== "0" &&
                                item.total_cost !== "" &&
                                item.total_cost !== undefined
                                  ? parseFloat(item.total_cost).toFixed(2)
                                  : "00.00"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                <a
                                  href={`${HOST_PREFIX}images/expenses/${item.id}/${item.bill_image}`}
                                  target="_blank"
                                >
                                  {item.bill_image}
                                </a>
                              </td>
                              <td>{item.created_time}</td>
                              {role === "SUPER_ADMIN" ? (
                                <>
                                  <td
                                    id={item.id}
                                    data-toggle="modal"
                                    onClick={() => {
                                      upadteList(item);
                                    }}
                                    data-target="#editExpense"
                                    className="fa fa-edit fa-lg"
                                  ></td>
                                  <td
                                    id={item.id}
                                    data-toggle="modal"
                                    data-target="#deleteExpense"
                                    className="fa fa-trash-o fa-lg"
                                    onClick={() => {
                                      setValues({
                                        ...values,
                                        delete_id: item.id,
                                      });
                                    }}
                                  ></td>
                                </>
                              ) : null}
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ height: "353px" }}>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td style={{ paddingTop: "150px" }}>
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={compenetRef}>
          <table>
            <thead>
              <tr>
                <th style={{ position: "relative", left: "35%" }}>
                  {storeName}
                </th>
              </tr>
            </thead>
            {storeAddress ? (
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[0]}, {storeAddress.split(",")[1]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[2]}, {storeAddress.split(",")[3]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[4]}, {storeAddress.split(",")[5]}
                  </th>
                </tr>
              </tbody>
            ) : null}
          </table>
          <table>
            <tbody style={{ float: "left" }}>
              <tr>
                <td>
                  <strong>{fromDate}</strong>
                  {" to "}
                  <strong>{toDate}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Sl No</th>
                <th style={{ textAlign: "center" }}>Category&nbsp;</th>
                <th style={{ textAlign: "center" }}>Sub Category&nbsp;</th>
                <th style={{ textAlign: "center" }}>Name&nbsp;</th>
                <th style={{ textAlign: "center" }}>Qunatity&nbsp;</th>
                <th style={{ textAlign: "center" }}>unit&nbsp;</th>
                <th style={{ textAlign: "center" }}>cost&nbsp;</th>
                <th style={{ textAlign: "center" }}>total Cost&nbsp;</th>
                <th style={{ textAlign: "center" }}>Bill Image&nbsp;</th>
                <th style={{ textAlign: "center" }}>Created At&nbsp;</th>
              </tr>
            </tbody>
            <tbody>
              {dayWiseData
                ? dayWiseData.slice(0, -1).map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.category_name}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.subcategory_name === "No Subcategory" ? (
                              <span style={{ color: "red" }}>
                                No Subcategory
                              </span>
                            ) : (
                              item.subcategory_name
                            )}
                            &nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.name}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.quantity}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.unit}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.cost}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.total_cost !== null &&
                            item.total_cost !== "0" &&
                            item.total_cost !== "" &&
                            item.total_cost !== undefined
                              ? parseFloat(item.total_cost).toFixed(2)
                              : "00.00"}
                            &nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            <a
                              href={`${HOST_PREFIX}images/expenses/${item.id}/${item.bill_image}`}
                              target="_blank"
                            >
                              {item.bill_image}&nbsp;
                            </a>
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.created_time}&nbsp;
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : null}
              <tr>
                <td>Printed on {new Date().toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

function CategoryWise(props) {
  const { fromDate, toDate, categoryWiseExpenses, changeDate, changePage } =
    props.data;
  let todayDate = new Date().toISOString().slice(0, 10);
  const compenetRef = useRef(null);
  return (
    <section className="client no-padding-top">
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <div
                  id="closeCard4"
                  aria-haspopup="true"
                  className="dropdown-toggle"
                >
                  <Col md={3}>
                    <Card ctAllIcons content={<></>} />
                  </Col>
                </div>
              </div>
            </div>
            <div
              className="card-header d-flex align-items-center"
              style={{
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 10,
                position: "relative",
              }}
            >
              <span
                onClick={() => changePage("default")}
                className="close-elements"
              >
                x
              </span>
              <h3 className="h4">Category Wise Reports</h3>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="fromDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="toDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div>
                  <a
                    href="#/"
                    onClick={() => {
                      props.pdfCheck(
                        categoryWiseExpenses,
                        "my-table5",
                        "CategoryWiseExpensesReports"
                      );
                    }}
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </div>
                <div style={{ zIndex: 3 }}>
                  <ReactToPrint
                    trigger={() => <a href="#/">Print</a>}
                    content={() => compenetRef.current}
                  />
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <div style={{ height: "400px", overflow: "auto" }}>
                  <table
                    className="table table-striped table-sm"
                    id="my-table5"
                  >
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Category Name</th>
                        <th>Item Count</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {categoryWiseExpenses ? (
                        categoryWiseExpenses.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.row_count}</td>
                              <td>
                                {item.total_amount !== null &&
                                item.total_amount !== "0" &&
                                item.total_amount !== "" &&
                                item.total_amount !== undefined
                                  ? parseFloat(item.total_amount).toFixed(2)
                                  : "00.00"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ height: "353px" }}>
                          <td></td>
                          <td></td>
                          <td style={{ paddingTop: "150px" }}>
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={compenetRef}>
          <table>
            <thead>
              <tr>
                <th style={{ position: "relative", left: "35%" }}>
                  {storeName}
                </th>
              </tr>
            </thead>
            {storeAddress ? (
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[0]}, {storeAddress.split(",")[1]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[2]}, {storeAddress.split(",")[3]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[4]}, {storeAddress.split(",")[5]}
                  </th>
                </tr>
              </tbody>
            ) : null}
          </table>
          <table>
            <tbody style={{ float: "left" }}>
              <tr>
                <td>
                  <strong>{fromDate}</strong>
                  {" to "}
                  <strong>{toDate}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Sl No</th>
                <th style={{ textAlign: "center" }}>Category Name&nbsp;</th>
                <th style={{ textAlign: "center" }}>Item Count&nbsp;</th>
                <th style={{ textAlign: "center" }}>Amount&nbsp;</th>
              </tr>
            </tbody>
            <tbody>
              {categoryWiseExpenses
                ? categoryWiseExpenses.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.name}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.row_count}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.total_amount !== null &&
                            item.total_amount !== "0" &&
                            item.total_amount !== "" &&
                            item.total_amount !== undefined
                              ? parseFloat(item.total_amount).toFixed(2)
                              : "00.00"}
                            &nbsp;
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : null}
              <tr>
                <td>Printed on {new Date().toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
function SubCategoryWise(props) {
  const {
    subCategoryWiseExpenses,
    changeDate,
    setCategory,
    categoryList,
    changePage,
    fromDate,
    toDate,
  } = props.data;
  let todayDate = new Date().toISOString().slice(0, 10);
  const compenetRef = useRef(null);
  return (
    <section className="client no-padding-top">
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <div
                  id="closeCard4"
                  aria-haspopup="true"
                  className="dropdown-toggle"
                >
                  <Col md={3}>
                    <Card ctAllIcons content={<></>} />
                  </Col>
                </div>
              </div>
            </div>
            <div
              className="card-header d-flex align-items-center"
              style={{
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              <span
                onClick={() => changePage("default")}
                className="close-elements"
              >
                x
              </span>
              <div>
              <h3 className="h4">Sub Category Wise Reports</h3>
                <select
                  onChange={setCategory}
                  className="form-control"
                  name="expcat"
                  id="selectexpcat"
                ><option value="selected disabled" >
                    Select Category
                  </option>
                  {categoryList !== "" &&
                  categoryList !== null &&
                  categoryList !== undefined ? (
                    categoryList.map((item, index) => {
                      return (
                        <option
                          key={index}
                          style={{ color: "black" }}
                          value={item.id}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  ) : (
                    <option value="" disabled>
                      No Details Found
                    </option>
                  )}
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 10,
                  flexWrap: "wrap",
                  justifyContent: "center",
                }}
              >
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="fromDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
                <div>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="provide Start Date"
                    defaultValue={todayDate}
                    name="toDate"
                    max={todayDate}
                    onChange={changeDate}
                  />
                </div>
              </div>
              <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                <div>
                  <a
                    href="#/"
                    onClick={() => {
                      props.pdfCheck(
                        subCategoryWiseExpenses,
                        "my-table9",
                        "SubCategoryWiseExpensesReports"
                      );
                    }}
                  >
                    <i className="fa fa-file-pdf-o"></i>
                  </a>
                </div>
                <div style={{ zIndex: 3 }}>
                  <ReactToPrint
                    trigger={() => <a href="#/">Print</a>}
                    content={() => compenetRef.current}
                  />
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <div style={{ height: "400px", overflow: "auto" }}>
                  <table
                    className="table table-striped table-sm"
                    id="my-table9"
                  >
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Sub Category Name</th>
                        <th>Item Count</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {subCategoryWiseExpenses ? (
                        subCategoryWiseExpenses.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.row_count}</td>
                              <td>
                                {item.total_amount !== null &&
                                item.total_amount !== "0" &&
                                item.total_amount !== "" &&
                                item.total_amount !== undefined
                                  ? parseFloat(item.total_amount).toFixed(2)
                                  : "00.00"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ height: "353px" }}>
                          <td></td>
                          <td></td>
                          <td style={{ paddingTop: "150px" }}>
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={compenetRef}>
          <table>
            <thead>
              <tr>
                <th style={{ position: "relative", left: "35%" }}>
                  {storeName}
                </th>
              </tr>
            </thead>
            {storeAddress ? (
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[0]}, {storeAddress.split(",")[1]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[2]}, {storeAddress.split(",")[3]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[4]}, {storeAddress.split(",")[5]}
                  </th>
                </tr>
              </tbody>
            ) : null}
          </table>
          <table>
            <tbody style={{ float: "left" }}>
              <tr>
                <td>
                  <strong>{fromDate}</strong>
                  {" to "}
                  <strong>{toDate}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Sl No</th>
                <th style={{ textAlign: "center" }}>Sub Category Name&nbsp;</th>
                <th style={{ textAlign: "center" }}>Item Count&nbsp;</th>
                <th style={{ textAlign: "center" }}>Amount&nbsp;</th>
              </tr>
            </tbody>
            <tbody>
              {subCategoryWiseExpenses
                ? subCategoryWiseExpenses.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.name}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.row_count}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.total_amount !== null &&
                            item.total_amount !== "0" &&
                            item.total_amount !== "" &&
                            item.total_amount !== undefined
                              ? parseFloat(item.total_amount).toFixed(2)
                              : "00.00"}
                            &nbsp;
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : null}
              <tr>
                <td>Printed on {new Date().toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}
function ItemWise(props) {
  const {
    itemWiseData,
    changeDate,
    fromDate,
    toDate,
    getExpensesItems,
    itemSubCategory,
    itemShowSubDetails,
    data,
    itemSubCatData,
    itemSetSubCategory,
    changePage,
  } = props.data;
  let todayDate = new Date().toISOString().slice(0, 10);
  const compenetRef = useRef(null);
  return (
    <section className="client no-padding-top">
      <div className="container-fluid">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-close">
              <div className="dropdown">
                <div
                  id="closeCard4"
                  aria-haspopup="true"
                  className="dropdown-toggle"
                >
                  <Col md={3}>
                    <Card ctAllIcons content={<></>} />
                  </Col>
                </div>
              </div>
            </div>
            <div
              className="card-header d-flex align-items-center"
              style={{
                justifyContent: "space-between",
                flexWrap: "wrap",
                gap: 10,
                flexDirection: "column",
              }}
            >
              <span
                onClick={() => changePage("default")}
                className="close-elements"
              >
                x
              </span>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <h3>Item Wise Reports</h3>
                <div style={{ display: "flex", gap: 20, flexWrap: "wrap" }}>
                  <div>
                    <a
                      href="#/"
                      onClick={() => {
                        props.pdfCheck(
                          itemWiseData,
                          "my-table10",
                          "ItemWiseExpensesReports"
                        );
                      }}
                    >
                      <i className="fa fa-file-pdf-o"></i>
                    </a>
                  </div>
                  <div style={{ zIndex: 3 }}>
                    <ReactToPrint
                      trigger={() => <a href="#/">Print</a>}
                      content={() => compenetRef.current}
                    />
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  gap: 10,
                }}
              >
                <div
                  className="d-flex"
                  style={{
                    gap: 15,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <div className="d-flex">
                    <select
                      onChange={getExpensesItems}
                      className="form-control"
                      name="expItems"
                      id="expItems"
                    >
                      <option value="selected disabled">
                        Select Category
                      </option>
                      {data !== "" && data !== null && data !== undefined ? (
                        data.map((item, index) => {
                          return (
                            <option
                              className={item.subcategory}
                              key={index}
                              style={{ color: "black" }}
                              value={item.id}
                            >
                              {item.name}
                            </option>
                          );
                        })
                      ) : (
                        <option value="" disabled>
                          No Details Found
                        </option>
                      )}
                    </select>
                  </div>
                  {itemShowSubDetails ? (
                    <div className="d-flex">
                      <select
                        onChange={itemSetSubCategory}
                        value={itemSubCategory}
                        className="form-control"
                        name="itemexpsubcat"
                        id="itemexpsubcat"
                      >
                        <option value="selected disabled">
                          Select Sub Category
                        </option>
                        {itemSubCatData !== "" &&
                        itemSubCatData !== null &&
                        itemSubCatData !== undefined ? (
                          itemSubCatData.map((item, index) => {
                            return (
                              <option
                                key={index}
                                style={{ color: "black" }}
                                value={item.id}
                              >
                                {item.name}
                              </option>
                            );
                          })
                        ) : (
                          <option value="" disabled>
                            No Sub Category Found
                          </option>
                        )}
                      </select>
                    </div>
                  ) : null}
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                >
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="provide Start Date"
                      defaultValue={todayDate}
                      name="fromDate"
                      max={todayDate}
                      onChange={changeDate}
                    />
                  </div>
                  <div>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="provide Start Date"
                      defaultValue={todayDate}
                      name="toDate"
                      max={todayDate}
                      onChange={changeDate}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body">
              <div className="table-responsive">
                <div style={{ height: "400px", overflow: "auto" }}>
                  <table
                    className="table table-striped table-sm"
                    id="my-table10"
                  >
                    <thead>
                      <tr>
                        <th>Sl No</th>
                        <th>Item Name</th>
                        <th>Item Count</th>
                        <th>Quntity</th>
                        <th>Unit</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {itemWiseData ? (
                        itemWiseData.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td scope="row">{index + 1}</td>
                              <td>{item.name}</td>
                              <td>{item.row_count}</td>
                              <td>{item.qty}</td>
                              <td>{item.unit}</td>
                              <td>
                                {item.total_amount !== null &&
                                item.total_amount !== "0" &&
                                item.total_amount !== "" &&
                                item.total_amount !== undefined
                                  ? parseFloat(item.total_amount).toFixed(2)
                                  : "00.00"}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ height: "353px" }}>
                          <td></td>
                          <td></td>
                          <td style={{ paddingTop: "150px" }}>
                            <h1 style={{ fontSize: "35px" }}> No Data </h1>
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ display: "none" }}>
        <div ref={compenetRef}>
          <table>
            <thead>
              <tr>
                <th style={{ position: "relative", left: "35%" }}>
                  {storeName}
                </th>
              </tr>
            </thead>
            {storeAddress ? (
              <tbody>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[0]}, {storeAddress.split(",")[1]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[2]}, {storeAddress.split(",")[3]},
                  </th>
                </tr>
                <tr>
                  <th style={{ textAlign: "center" }}>
                    {storeAddress.split(",")[4]}, {storeAddress.split(",")[5]}
                  </th>
                </tr>
              </tbody>
            ) : null}
          </table>
          <table>
            <tbody style={{ float: "left" }}>
              <tr>
                <td>
                  <strong>{fromDate}</strong>
                  {" to "}
                  <strong>{toDate}</strong>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <table>
            <tbody>
              <tr>
                <th>Sl No</th>
                <th style={{ textAlign: "center" }}>Item Name&nbsp;</th>
                <th style={{ textAlign: "center" }}>Item Count&nbsp;</th>
                <th style={{ textAlign: "center" }}>Quntity&nbsp;</th>
                <th style={{ textAlign: "center" }}>Unit&nbsp;</th>
                <th style={{ textAlign: "center" }}>Amount&nbsp;</th>
              </tr>
            </tbody>
            <tbody>
              {itemWiseData
                ? itemWiseData.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        <tr key={index}>
                          <td scope="row">{index + 1}</td>
                          <td style={{ textAlign: "center" }}>
                            {item.name}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.row_count}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.qty}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.unit}&nbsp;
                          </td>
                          <td style={{ textAlign: "center" }}>
                            {item.total_amount !== null &&
                            item.total_amount !== "0" &&
                            item.total_amount !== "" &&
                            item.total_amount !== undefined
                              ? parseFloat(item.total_amount).toFixed(2)
                              : "00.00"}
                            &nbsp;
                          </td>
                        </tr>
                      </React.Fragment>
                    );
                  })
                : null}
              <tr>
                <td>Printed on {new Date().toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
}

export default ExpensesReportComponent;
